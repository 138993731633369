import React from "react";
import PropTypes from "prop-types";
import { Checkbox, FormControlLabel, Typography } from "@mui/material";

const DayFilter = ({ label, checked, onChange }) => {
  return (
    <FormControlLabel
      sx={{ marginBottom: 0 }}
      checked={checked}
      control={<Checkbox size="small" />}
      onChange={onChange}
      label={<Typography sx={{ fontSize: "11px" }}>{label}</Typography>}
    />
  );
};

DayFilter.propTypes = {
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
};

export default DayFilter;
