import React from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  Box,
  CardActions,
  Button,
} from "@mui/material";
import {
  presentationTrips,
  defaultPresentationTrip,
} from "../Trips/trips-data";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "gatsby";
import { orange } from "@mui/material/colors";

const Results = ({ results }) => {
  const { t } = useTranslation();

  return results === null || results.length === 0 ? (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6} md={4}>
        {t("trips.no-results-found")}
      </Grid>
    </Grid>
  ) : (
    <Grid container spacing={3}>
      {results.map((result, index) => {
        let presentationTrip = presentationTrips.find(
          (x) => x.tripObjectId === result.tripId
        );

        if (presentationTrip == null)
          presentationTrip = defaultPresentationTrip;

        return (
          <Grid key={index} item xs={12} sm={6} md={4}>
            <Card>
              <CardMedia
                component="img"
                height="150"
                image={presentationTrip.backgroundImage}
              />
              <CardContent>
                <Typography variant="h6" sx={{ minHeight: "64px" }}>
                  {t(presentationTrip.title)}
                </Typography>
                <Box
                  sx={{
                    fontSize: "12px",
                    textTransform: "uppercase",
                    marginTop: "16px",
                  }}
                >
                  <Trans i18nKey={presentationTrip.priceAdult}>
                    Price per adult: <strong>-- €</strong>
                  </Trans>
                </Box>
                <Box sx={{ fontSize: "12px", textTransform: "uppercase" }}>
                  <Trans i18nKey={presentationTrip.priceChild}>
                    Price per child: <strong>-- €</strong>
                  </Trans>
                </Box>
              </CardContent>
              <CardActions
                sx={{
                  display: "fex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  sx={{
                    backgroundColor: orange[600],
                    "&:hover": { backgroundColor: orange[800], color: "white" },
                  }}
                  variant="contained"
                  component={Link}
                  to={presentationTrip.url}
                >
                  {t("trips.more-info")}
                </Button>
              </CardActions>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
};

Results.propTypes = {};

export default Results;
