import React from "react";
import PropTypes from "prop-types";
import { Breadcrumbs, Typography } from "@mui/material";
import { Link } from "gatsby";
const BreadcrumbsWrapper = ({ items }) => {
  return (
    <Breadcrumbs separator="›" aria-label="breadcrumb">
      {items.map((item, key) =>
        item.isActive ? (
          <Typography key={key}>{item.label}</Typography>
        ) : (
          <Link underline="hover" key={key} to={item.link}>
            {item.label}
          </Link>
        )
      )}
    </Breadcrumbs>
  );
};

BreadcrumbsWrapper.propTypes = {
  items: PropTypes.array.isRequired,
};

export default BreadcrumbsWrapper;
