import React, { useContext, useEffect, useState } from "react";
import Page from "../components/Page";
import { Container, Grid, Typography } from "@mui/material";
import BreadcrumbsWrapper from "../components/Common/BreadcrumbsWrapper";
import Filters from "../components/SearchResults/Filters";
import Results from "../components/SearchResults/Results";
import { searchTrips } from "../api/bookingApi";
import { SearchContext } from "../components/ContextProviders/SearchContextProvider";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";

const SearchResults = () => {
  const { t } = useTranslation();

  const breadcrumbs = [
    { label: t("menu.home"), link: "/" },
    { label: "Search results", isActive: true },
  ];

  const {
    startDate,
    endDate,
    mondaySelected,
    tuesdaySelected,
    wednesdaySelected,
    thursdaySelected,
    fridaySelected,
    saturdaySelected,
    sundaySelected,
  } = useContext(SearchContext);

  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    searchTrips(
      format(startDate === null ? new Date() : startDate, "yyyy-MM-dd"),
      format(endDate === null ? new Date() : endDate, "yyyy-MM-dd"),
      mondaySelected,
      tuesdaySelected,
      wednesdaySelected,
      thursdaySelected,
      fridaySelected,
      saturdaySelected,
      sundaySelected
    ).then((response) => {
      setSearchResults(response.data);
    });
  }, [
    startDate,
    endDate,
    mondaySelected,
    tuesdaySelected,
    wednesdaySelected,
    thursdaySelected,
    fridaySelected,
    saturdaySelected,
    sundaySelected,
  ]);
  return (
    <Page title="Search results" description="We will find you the best trip">
      <Container>
        <section className="section" id="search-results">
          <BreadcrumbsWrapper items={breadcrumbs} />
          <Typography fontSize={48} variant="h1">
            {t("trips.search-results-message")}
          </Typography>
          <Grid container spacing={3} mt={3}>
            <Grid item xs={12} sm={3}>
              <Filters />
            </Grid>
            <Grid item xs={12} sm={9}>
              <Results results={searchResults} />
            </Grid>
          </Grid>
        </section>
      </Container>
    </Page>
  );
};

SearchResults.propTypes = {};

export default SearchResults;
