import React, { useContext } from "react";
import { Box, Card, CardContent, FormGroup, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { SearchContext } from "../ContextProviders/SearchContextProvider";
import DayFilter from "./DayFilter";

const Filters = () => {
  const { t } = useTranslation();
  const {
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    mondaySelected,
    tuesdaySelected,
    wednesdaySelected,
    thursdaySelected,
    fridaySelected,
    saturdaySelected,
    sundaySelected,
    setMondaySelected,
    setTuesdaySelected,
    setWednesdaySelected,
    setThursdaySelected,
    setFridaySelected,
    setSaturdaySelected,
    setSundaySelected,
  } = useContext(SearchContext);

  return (
    <>
      <Card>
        <CardContent>
          <Typography sx={{ fontWeight: "bold", fontSize: "13px" }}>
            {t("trips.search-dates")}
          </Typography>
          <Box mt={2}>
            <MobileDatePicker
              sx={{ color: "white" }}
              slotProps={{ textField: { fullWidth: true } }}
              disablePast
              closeOnSelect
              onChange={(date) => setStartDate(date)}
              value={startDate === null ? new Date() : startDate}
              label={t("trips.search-start")}
            />
          </Box>
          <Box mt={2}>
            <MobileDatePicker
              disablePast
              slotProps={{ textField: { fullWidth: true } }}
              value={endDate === null ? new Date() : endDate}
              closeOnSelect
              minDate={startDate}
              onChange={(date) => setEndDate(date)}
              label={t("trips.search-end")}
            />
          </Box>
        </CardContent>
      </Card>
      <Card sx={{ marginTop: "10px" }}>
        <CardContent>
          <Typography sx={{ fontWeight: "bold", fontSize: "13px" }}>
            {t("trips.departure-days")}
          </Typography>
          <FormGroup sx={{ marginTop: "10px" }}>
            <DayFilter
              label={t("trips.filter-monday")}
              checked={mondaySelected}
              onChange={(event) => setMondaySelected(event.target.checked)}
            />
            <DayFilter
              label={t("trips.filter-tuesday")}
              checked={tuesdaySelected}
              onChange={(event) => setTuesdaySelected(event.target.checked)}
            />
            <DayFilter
              label={t("trips.filter-wednesday")}
              checked={wednesdaySelected}
              onChange={(event) => setWednesdaySelected(event.target.checked)}
            />
            <DayFilter
              label={t("trips.filter-thursday")}
              checked={thursdaySelected}
              onChange={(event) => setThursdaySelected(event.target.checked)}
            />
            <DayFilter
              label={t("trips.filter-friday")}
              checked={fridaySelected}
              onChange={(event) => setFridaySelected(event.target.checked)}
            />
            <DayFilter
              label={t("trips.filter-saturday")}
              checked={saturdaySelected}
              onChange={(event) => setSaturdaySelected(event.target.checked)}
            />
            <DayFilter
              label={t("trips.filter-sunday")}
              checked={sundaySelected}
              onChange={(event) => setSundaySelected(event.target.checked)}
            />
          </FormGroup>
        </CardContent>
      </Card>
    </>
  );
};

export default Filters;
